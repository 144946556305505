import { metrikaGoal } from '@src/utils/metrikaGoal'
import { Flexbox, Paragraph } from '@stage-ui/core'
import FlexboxTypes from '@stage-ui/core/layout/Flexbox/types'
import { ArrowRight, PlusCircle } from '@stage-ui/icons'

type PlusBlockProps = {
  title: string
  label: string
  anchor: string
} & FlexboxTypes.Props

const PlusBlock = (props: PlusBlockProps) => {
  const { title, label, anchor, onClick, ...restProps } = props

  return (
    <a
      href={`#${anchor}`}
      style={{ textDecoration: 'none' }}
      onClick={() => {
        metrikaGoal('clickPerk', {
          title,
        })
      }}
    >
      <Flexbox
        {...restProps}
        alignItems="flex-start"
        style={{
          '#arrow': {
            transition: 'all 0.25s',
          },
          ':hover #arrow': {
            transform: 'translateX(0.25rem)',
          },
        }}
      >
        <PlusCircle color="black" size="1.5rem" mt="0" mr="m" />
        <Flexbox column>
          <Paragraph weight={600} color="black" style={{ textTransform: 'uppercase' }}>
            {title}
          </Paragraph>
          <Flexbox
            alignItems="center"
            onClick={onClick}
            mt={['1.5rem', '0']}
            mb={['0', '1.5rem']}
          >
            <Paragraph color="red700" style={{ textTransform: 'uppercase' }}>
              {label}
            </Paragraph>
            <ArrowRight id="arrow" color="red700" />
          </Flexbox>
        </Flexbox>
      </Flexbox>
    </a>
  )
}

export default PlusBlock
